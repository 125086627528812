import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom'

import Start from "./features/start/Start";
import Home from "./features/home/Home";
import Admin from './features/admin/Admin';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCpzBGaLe0d2HegB-USlCzstF70QJPpKoU",
  authDomain: "schocke-2ff25.firebaseapp.com",
  databaseURL: "https://schocke-2ff25.firebaseio.com",
  projectId: "schocke-2ff25",
  storageBucket: "schocke-2ff25.appspot.com",
  messagingSenderId: "923532180322",
  appId: "1:923532180322:web:1ad6531dbc7e6966660a3b",
  measurementId: "G-994BF2ZFPC"
};
  
firebase.initializeApp(firebaseConfig);

function App() {
  return (
      <Router>
          <Route exact path={'/'} component={Start} />
          <Route path={'/home'} component={Home} />
          <Route path={'/success'} component={Home} />
          <Route path={'/cancel'} component={Home} />
          <Route path={'/error'} component={Home} />
          <Route path={'/admin'} component={Admin} />
      </Router>
  );
}

export default App;
