import React, { Component } from 'react'
import './AdminView.scss'

export default class AdminView extends Component {

    constructor() {
        super();
        this.state = {};
      }

    render() {

        const orders = [
            {
                firstName: 'Dominik',
                lastName: 'Näf',
                email: 'naef.dominik@hotmail.com',
                street: 'Zürichstrasse 65d',
                plz: 8413,
                place: 'Neftenbach',
                time: '03.05.2021 - 20:37',
                price: 34,
                method: 'Twint',
                items: [
                    {
                        quantity: 1,
                        title: 'Herz über Geld (39 - 42)'
                    },
                    {
                        quantity: 1,
                        title: 'Herz über Geld (43 - 46)'
                    }
                ]
            },
            {
                firstName: 'Dominik',
                lastName: 'Näf',
                email: 'naef.dominik@hotmail.com',
                street: 'Zürichstrasse 65d',
                plz: 8413,
                place: 'Neftenbach',
                time: '03.05.2021 - 20:37',
                price: 34,
                method: 'Twint',
                items: [
                    {
                        quantity: 1,
                        title: 'Herz über Geld (39 - 42)'
                    },
                    {
                        quantity: 1,
                        title: 'Herz über Geld (43 - 46)'
                    }
                ]
            }
        ];

        return (
            <div className='AdminView'>

                <div>
                    <div>Kunden</div>
                    <div>Produkte</div>
                    <div>Bestellungen</div>
                    <div>Einnahmen</div>
                </div>

                <div>
                    <div>Bestellungen</div>
                    <div>
                        {orders.map(order => (
                            <div className='AdminView__item'>
                                <div className='AdminView__itemCol'>
                                    <div>{order.firstName} {order.lastName}</div>
                                    <div>{order.email}</div>
                                    <div>{order.street}, {order.plz} {order.place}</div>
                                </div>
                                <div className='AdminView__itemCol'>
                                    {order.items.map(item => (
                                        <div>{item.quantity}x {item.title}</div>
                                    ))}
                                </div>
                                <div className='AdminView__itemCol'>
                                    <div>CHF {order.price}, {order.method}</div>
                                    <div>{order.time}</div>
                                </div>
                                <div className='AdminView__itemCol'>
                                    <button>Neu</button>
                                    <button>In Bearbeitung</button>
                                    <button>Abgeschlossen</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>
        )
    }
}