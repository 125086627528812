import React, { Component } from 'react'
import Lightbox from 'react-datatrans-light-box'
import './Home.scss'

import Logo from '../../assets/logoWebsite.png';

export default class Home extends Component {

    constructor() {
        super();

        this.state = {
            transactionId: null
        };
      }

    render() {

        const start = (paymentMethod) => {
            fetch('https://us-central1-zweituusig.cloudfunctions.net/app/payment', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "paymentMethod": paymentMethod,
                    "amount": 700,
                })
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    console.log(response);
                }
            })
            .then(data => this.setState({ transactionId: data.transactionId }))
            .catch(error => console.log(error));
        }

        const onLoaded = () => console.log('Loaded');
        const onOpened = () => console.log('Opened');
        const onCancelled = () => this.setState({ transactionId: null });
        const onError = (data) => {
            console.log('Error:', data);
            this.setState({ transactionId: null });
        };

        return (
            <div className='Home'>

                <img src={Logo} alt={'schocke!'}/>
                <h1>DROP TITLE</h1>

                {this.state.transactionId
                    ? <Lightbox
                        transactionId={this.state.transactionId}
                        production={false}
                        onLoaded={onLoaded}
                        onOpened={onOpened}
                        onCancelled={onCancelled}
                        onError={onError}
                      />
                    : <div>
                        <button onClick={() => start('VIS')}>VISA</button>
                        <button onClick={() => start('ECA')}>MasterCard</button>
                        <button onClick={() => start('AMX')}>American Express</button>
                        <button onClick={() => start('PFC')}>PostFinance Card</button>
                        <button onClick={() => start('PEF')}>PostFinance E-Finance</button>
                        <button onClick={() => start('PAP')}>PayPal</button>
                        <button onClick={() => start('DIN')}>Diners</button>
                        <button onClick={() => start('DIS')}>Discover</button>
                        <button onClick={() => start('ELV')}>SEPA</button>
                        <button onClick={() => start('TWI')}>TWINT</button>
                    </div>
                }

            </div >
        )
    }
}