import React, { Component } from 'react'
import './Start.scss'

// TODO: clean up
// TODO: map or timeline showing production process

export default class Start extends Component {

    render() {
        return (
            <div className='Start'>

                <div id="container">
                    <div id="circle">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enable-background="new 0 0 300 300" xmlSpace="preserve">
                            <defs>
                                <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 " />
                            </defs>
                            <circle cx="150" cy="100" r="75" fill="none" />
                            <g>
                                <use xlinkHref="#circlePath" fill="none" />
                                <text fill="#FFFFFF">
                                    <textPath xlinkHref="#circlePath">COMING SOON! COMING SOON! COMING SOON!</textPath>
                                </text>
                            </g>
                        </svg>
                    </div>
                </div>

                <div id="text">
                    <h1>schocke.</h1>
                    <p>socke wo schocked,</p>
                    <p>made in ch.</p>
                </div>

            </div>
        )
    }
}