import React, { Component } from 'react'
import firebase from "firebase/app";
import AdminView from './AdminView';
import './Admin.scss'

export default class Admin extends Component {

    constructor() {
        super();
        this.state = {
            username: '',
            password: '',
            errorMessage: '',
            user: null
        };
      }

    render() {

        const handleChangeUsername = (event) => {
            this.setState({ username: event.target.value });
        };
        
        const handleChangePassword = (event) => {
            this.setState({ password: event.target.value });
        };

        const handleLogin = (event) => {
            firebase.auth().signInWithEmailAndPassword(this.state.username, this.state.password)
                .then((userCredential) => {
                    this.setState({ user: userCredential.user });
                    this.setState({ errorMessage: '' });
                })
                .catch((error) => {
                    this.setState({ errorMessage: error.message });
                });

            event.preventDefault();
        };

        const handleLogout = () => {
            this.setState({ user: null});
        };

        return (
            <div className='Admin'>

                {this.state.user ? (
                    <div>
                        <button onClick={handleLogout}>Logout</button>
                        <AdminView />
                    </div> 
                ) : (
                    <div>
                        <form onSubmit={handleLogin}>
                            <input type="email" placeholder="E-Mail" value={this.state.username} onChange={handleChangeUsername} /><br/>
                            <input type="password" placeholder="Password" value={this.state.password} onChange={handleChangePassword} /><br/>
                            <input type="submit" value="Login" />    
                        </form>
                        <div>{this.state.errorMessage}</div>
                    </div>
                )}

            </div>
        )
    }
}